jQuery.fn.preventDoubleSubmission = function() {
  $(this).on('submit',function(e){
    var $form = $(this);

    $form.find('.noscript').remove();

    if ($form.data('submitted') === true) {
      // Previously submitted - don't submit again
      e.preventDefault();
    } else {
      // Mark it so that the next submit can be ignored
      $form.data('submitted', true);
      setTimeout(function() { $form.data('submitted', false) }, 10000);    //unlock it after 10s!
    }
  });

  // Keep chainability
  return this;
};

jQuery.fn.switchClass = function(newClass, oldClass) {
  if(!this.hasClass(newClass)) this.addClass(newClass);
  this.removeClass(oldClass);
}

jQuery.fn.serializeObject = function() {
  var arrayData, objectData;
  arrayData = this.serializeArray();
  objectData = {};

  $.each(arrayData, function() {
    var value;

    if (this.value != null) {
      value = this.value;
    } else {
      value = '';
    }

    if (objectData[this.name] != null) {
      if (!objectData[this.name].push) {
        objectData[this.name] = [objectData[this.name]];
      }

      objectData[this.name].push(value);
    } else {
      objectData[this.name] = value;
    }
  });

  return objectData;
};

var tooltips = {
  init: function() {    
    $('[data-toggle="tooltip"]').tooltip({container: '#content-wrapper'});
  }
};

global.onload = {
  init: function() {
    $('.data-table').DataTable({
      language: {
        url: 'https://cdn.datatables.net/plug-ins/1.10.20/i18n/Slovenian.json'
      }
    });

    // init tooltips
    tooltips.init();
    // confirm modal defaults
    dataConfirmModal.setDefaults({
      title: 'Potrditev',
      commit: 'Potrdi',
      commitClass: 'btn btn-sm btn-success px-4',
      cancel: 'Prekliči',
      cancelClass: 'btn btn-sm btn-danger px-4'
    });
    // prevent multiple form submissions
    $('form').preventDoubleSubmission();
    // init custom select pickers
    selectPicker.init();
    setTimeout(bsCustomInputFile.init, 500);
  }
};

global.selectPicker = {
  init: function() {
    $('.select-picker').selectpicker();
    $('.noscript .select-picker').selectpicker('destroy');
    $('.select-picker').on('shown.bs.select', function() {
      var $input = $(this).parent().find('.bs-searchbox input');
      setTimeout(function() { $input.focus(); }, 100);
    });
  }
}

global.season = {
  sl: {
    "format": "DD.MM.YYYY, HH:mm",
    "separator": " - ",
    "firstDay": 1
  },
  init: function(translations) {
    jQuery.extend(season.sl, translations);
    season.initRangePickers();
    season.initDateTimePickers();
    initAddRemoveComponent('license_period', null, season.addCallback);
    initAddRemoveComponent('registration_period', null, season.addCallback);
  },
  initDateTimePickers: function($el) {
    if(!$el) $el = $('.single-date-picker');
    $el.each(function() {
      var $item = $(this);
      var datetime = $item.data('datetime');
      $item.daterangepicker({
        locale: season.sl,
        autoUpdateInput: !!datetime,
        startDate: datetime,
        timePicker: true,
        timePicker24Hour: true,
        timePickerSeconds: false,
        singleDatePicker: true
      });
      $item.on('apply.daterangepicker', function (ev, picker) {
        $(this).val(picker.startDate.format(season.sl.format));
        $(this).closest('.reminder-datetime').find('.datetime').val(picker.startDate.format('YYYY-MM-DD HH:mm'));
      });
    });
  },
  initRangePickers: function($el) {
    if(!$el) $el = $('.date-range-picker');
    $el.each(function() {
      var $item = $(this);
      var startDate = $item.data('startdate');
      var endDate = $item.data('enddate');
      $item.daterangepicker({
        locale: season.sl,
        autoUpdateInput: !!(startDate && endDate),
        startDate: startDate,
        endDate: endDate,
        timePicker: true,
        timePicker24Hour: true,
        timePickerSeconds: false
      });

      $item.on('apply.daterangepicker', function (ev, picker) {
        $(this).val(picker.startDate.format(season.sl.format) + " - " + picker.endDate.format(season.sl.format));
        $(this).closest('.period').find('.startdate').val(picker.startDate.format('YYYY-MM-DD HH:mm'));
        $(this).closest('.period').find('.enddate').val(picker.endDate.format('YYYY-MM-DD HH:mm'));
      });

      // $item.on('cancel.daterangepicker', function (ev, picker) {
      //   $(this).val('');
      // });
    });

    // $el.daterangepicker({
    //   locale: season.sl,
    //   autoUpdateInput: false
    // }, function(start, end, label) {
    //   var $el = this.element;
    //   var $period = $el.closest('.period');
    //   // set period hidden fields
    //   console.log('New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')');
    // });
  },
  addCallback: function(e, $el) {
    season.initRangePickers($el.find('.date-range-picker'));
    season.initDateTimePickers($el.find('.single-date-picker'));
  }
};

var overrides = {
  spinnerAjax: function($holder, url, settings) {
    $holder.html("<img src='"+window.global.spinner+"' />").show();
    settings = settings || {};
    settings.complete = function() { $holder.html("").hide(); };
    $.ajax(url, settings);
  }
};

global.radios = {
  init: function() {
    $(document).on('click', '.choice', radios.select);
  },
  select: function() {
    // handle classes and checked property first
    var $self = $(this);
    var $parent = $self.closest('.row');
    $parent.find('.choice').removeClass('active');
    $self.addClass('active');

    $parent.find('[type="radio"]').removeAttr('checked');
    $self.find('[type="radio"]').prop('checked','true').trigger('change');
  }
}

global.license = {
  data: {
    callInProgress: false,
    DEFAULT_SAVE_TRESHOLD: 500
  },
  init: function(competitionType2ranks, updateUrl, emailUrl, codings, skipBindings) {
    license.data.competitionType2ranks = competitionType2ranks;
    license.data.updateUrl = updateUrl;
    license.data.emailUrl = emailUrl;
    license.data.codings = codings;

    if(skipBindings) {
      selectPicker.init();
    } else {
      $(document).on('changed.bs.select', 'select.competition-type-select', license.competitionTypeChange);
      $(document).on('changed.bs.select', 'select.employment-status-select', license.employmentStatusChange);
      $(document).on('changed.bs.select', 'select.not-in-club-reason-select', license.notInClubReasonChange);
      $(document).on('changed.bs.select', 'select.role-select', license.roleChange);
      $(document).on('changed.bs.select', 'select.staff-role-select', license.staffRoleChange);
      $(document).on('changed.bs.select', 'select.representative-role-select', license.representativeRoleChange);
      initAddRemoveComponent('participation', null, license.flashButton);
      initAddRemoveComponent('representative', null, license.flashButton);
      initAddRemoveComponent('stamp', null, license.flashButton);
      initAddRemoveComponent('last_employee', null, license.flashButton);
      initAddRemoveComponent('legal-file', null, license.flashButton);
      initAddRemoveComponent('license_venue', null, license.flashButton);
      initAddRemoveComponent('staff', null, license.flashButton);
      initAddRemoveComponent('current_employee', null, license.flashButton);
      $(document).on('click', '.save-license-btn', license.saveLicense);
      $(document).on('change', '.legal-term, .staff-term', function() { license.onTermChange($(this)) });
      $(document).on('change', 'select, [type="file"], [type="checkbox"]:not(#showerrors), [type="text"]', license.flashButton);
      $(document).on('change', '#showerrors', license.saveLicense);
      $(document).on('click', '.remove-file', license.removeFile);
      $(document).on('show.bs.modal', '#actionmodal', license.showActionModal);
      $(document).on('show.bs.modal', '#documentsmodal', license.showDocumentsModal);
      $(document).on('change', '#documentsmodal select', license.onSeasonChange);
      $(document).on('show.bs.modal', '#explanationmodal', license.showExplanationModal);
    }

    // $('.select-picker').selectpicker();
    // license.refreshRankOptions();
    license.closeAlerts();
    license.handleLegalTermCheckboxes();
    bsCustomInputFile.init();
  },
  showExplanationModal: function(e) {
    var $btn = $(e.relatedTarget);
    var error = $btn.data('error');

    var $modal = $(this);
    if($btn.data('readonly')) {
      $modal.find('.error-explanation-field').html(error);
    } else {
      $modal.find('.error-explanation-field').val(error);

      $modal.find('.save-btn').unbind('click').click(function(e) {
        var txt = $modal.find('.error-explanation-field').val();
        $btn.parent().find('.error-explanation-hidden-field').val(txt);

        license.saveLicense(e);
        $modal.modal('hide');
      });
    }

  },
  onSeasonChange: function() {
    $('.documents-table').hide();
    $('.documents-table.documents-table-' + $(this).val()).show();
  },
  showDocumentsModal: function(e) {
    var $btn = $(e.relatedTarget);
    var searchText = $btn.data('search');
    var parentSelector = $btn.data('klass');

    var $modal = $(this);

    $modal.find('.modal-select-btn').unbind('click').click(function(e) {
      var $self = $(this);
      var signedId = $self.data('signedid');

      $btn.closest(parentSelector).find('.signed-id-file').val(signedId);
      license.saveLicense(e);
      $modal.modal('hide');
    });

    $modal.find("[type='search']").val(searchText);
    $modal.find("[type='search']").trigger( $.Event( "keyup", { which: 13 } ) );
  },
  showActionModal: function(e) {
    var $trigger = $(e.relatedTarget);
    var $content = $('#actionmodal').find('.modal-content');

    $content.find('.modal-body, .modal-footer').remove();
    $content.append('<div class="modal-body text-center"><div class="spinner-border"></div></div>');

    $.ajax(license.data.emailUrl, {
      method: 'POST',
      data: {
        "email[type]": $trigger.data('emailtype'),
        license_id: $trigger.data('licenseid'),
        email_action: $trigger.data('action'),
        default: true
      },
      success: function(data) {
        var $content = $('#actionmodal').find('.modal-content');
        $content.find('.modal-body').remove();
        $content.append(data.html);
      },
      error: function(x) {
        console.log("error", x);
      }
    });
  },
  representativeRoleChange: function() {
    var $parent = $(this).closest('.representative');
    if($(this).val() === license.data.codings.REPRESENTATIVE_ROLE_OTHER) {
      $parent.find('.other').show();
    } else {
      $parent.find('.other').hide();
    }
  },
  staffRoleChange: function() {
    var val = $(this).val();
    var $parent = $(this).closest('.staff');

    if(val === license.data.codings.STAFF_ROLE_COACH) {
      $parent.find('.staff-categories').show();
    } else {
      $parent.find('.staff-categories').hide().find('select').val('');
    }

    if(val === license.data.codings.STAFF_ROLE_SECURITY || val === license.data.codings.STAFF_ROLE_WEBSITE) {
      $parent.find('.staff-last-name').hide().find('input').val('');
      $parent.find('.staff-first-name').switchClass('col-sm-4', 'col-sm-2');
    } else {
      $parent.find('.staff-last-name').show();
      $parent.find('.staff-first-name').switchClass('col-sm-2', 'col-sm-4');
    }
  },
  removeFile: function(e) {
    e.preventDefault();
    license.flashButton();
    
    var $self = $(this);
    var $form = $self.closest('form');
    var data = $self.data();
    
    $self.parent().find('.show-file').remove();
    $self.remove();

    $form.append('<input type="hidden" value="true" name="purge['+data.type+']['+data.id+']['+data.field+']" />');
    return false;
  },
  flashButton: function() {
    $('.save-license-btn:not(.flash)').addClass('flash');
    $('.action-btn').hide();
  },
  roleChange: function() {
    var $self = $(this);
    var $parent = $self.closest('.main-form-row');
    if($self.val() === license.data.codings.EMPLOYEE_ROLE_OTHER) {
      $parent.find('.other-role').show();
      $parent.find('.employment-status').switchClass('col-sm-4', 'col-sm-6');
      $parent.find('.role').switchClass('col-sm-4', 'col-sm-6');
    } else {
      $parent.find('.other-role').hide();
      $parent.find('.employment-status').switchClass('col-sm-6', 'col-sm-4');
      $parent.find('.role').switchClass('col-sm-6', 'col-sm-4');
    }
  },
  employmentStatusChange: function() {
    var $self = $(this);
    var $parent = $self.closest('.main-form-row');
    if($self.val() === license.data.codings.EMPLOYMENT_STATUS_UNDER_CONTRACT) {
      $parent.find('.statement').show();
    } else {
      $parent.find('.statement').hide();
    }

    if($self.val() === license.data.codings.EMPLOYMENT_STATUS_NOT_IN_CLUB) {
      $parent.find('.not-in-club-reason').show();
      $parent.find('.not-in-club-reason-select').trigger('change');
    } else {
      $parent.find('.not-in-club-reason').hide();
    }
  },
  notInClubReasonChange: function () {
    var $self = $(this);
    var $parent = $self.closest('.main-form-row');
    if($self.val() === license.data.codings.NOT_IN_CLUB_REASON_OTHER || $self.val() === license.data.codings.NOT_IN_CLUB_REASON_AGREEMENT) {
      $parent.find('.not-in-club-reason-details').show();
    } else {
      $parent.find('.not-in-club-reason-details').hide();
    }
  },
  handleLegalTermCheckboxes: function() {
    $('.legal-term, .staff-term').each(function() {
      license.onTermChange($(this));
    });
  },
  onTermChange: function($self) {
    var $parent = $self.closest('.form-check');
    $parent.find('[type="hidden"]').remove();
    if($self.prop('checked') === false) {
      $parent.append('<input type="hidden" name="'+$self.attr('name')+'" value="0" />');
    }
  },
  closeAlerts() {
    setTimeout(function() {
      $('.alert').closest('.container.alert-minus-margin').fadeOut(400, function() { $(this).remove(); });
    }, 1000);
  },
  competitionTypeChange: function() {
    var val = $(this).val();
    var $rankSelect = $(this).closest('.form-row').find('select.rank-select');
    $rankSelect.empty().append( license.buildRankOptions(license.data.competitionType2ranks[val]) );
    $rankSelect.selectpicker('refresh');

    var $noteDiv = $(this).closest('.form-row').find('.note');
    if([license.data.codings.ABSOLUTE_TYPE, license.data.codings.U20_TYPE, license.data.codings.U19_TYPE, license.data.codings.U18_TYPE, license.data.codings.U17_TYPE, license.data.codings.U16_TYPE, license.data.codings.U15_TYPE, license.data.codings.U14_TYPE, license.data.codings.U13_TYPE, license.data.codings.U12_TYPE, license.data.codings.U11_TYPE, license.data.codings.U10_TYPE, license.data.codings.U9_TYPE].indexOf(val) >= 0) {
      $noteDiv.hide();
    } else {
      $noteDiv.show();
    }
  },
  buildRankOptions: function(ranks) {
    var html = "";
    $.each(ranks, function(i, rank) {
      html += "<option value='"+rank.value+"'>"+rank.name+"</option>";
    });
    return html;
  },
  refreshRankOptions: function() {
    $('.participation-wrapper').each(function() {
      var $self = $(this);
      var val = registration.getType($self);
      var $rankSelect = $self.find('select.rank-select');
      var rankVal = $rankSelect.val();
      $rankSelect.empty().append( license.buildRankOptions(license.data.competitionType2ranks[val]) );
      $rankSelect.val(rankVal);
      $rankSelect.selectpicker('refresh');
    });
  },
  getFormData: function(e) {
    var $form = $('form');
    var data = new FormData($form[0]);
    
    var tmp = {};
    $form.find('[type="checkbox"]').each(function() {
      var $item = $(this);
      if(!tmp[$item.attr('name')]) {
        tmp[$item.attr('name')] = new Array();
      }
      if($item.prop('checked') === true) {
        tmp[$item.attr('name')].push(1);
      } else {
        tmp[$item.attr('name')].push(0);
      }
    });

    for (const [name, arr] of Object.entries(tmp)) {
      data.append(name, arr);
    }
    // // Display the key/value pairs
    // for (var pair of data.entries()) {
    //     console.log(pair[0]+ ', ' + pair[1]); 
    // }
    return data;
  },
  autoSaveLicense: function(e) {
    if(license.data.callInProgress) return false;
    $('.submit-license-btn').remove();
    license.data.saveTime = new Date().getTime();
    setTimeout(function() {
      var current = new Date().getTime();
      if(license.data.saveTime <= current - license.data.DEFAULT_SAVE_TRESHOLD) {
        license.saveLicense(e);
      }
    }, license.data.DEFAULT_SAVE_TRESHOLD);
  },
  saveLicense: function(e) {
    e.preventDefault();
    if(license.data.callInProgress) return false;
    license.data.callInProgress = true;

    $('.noscript').remove();
    $('.save-license-btn').hide();
    $('.saving-license-btn').show();

    $.ajax(license.data.updateUrl, {
      method: 'PUT',
      data: license.getFormData(),
      mimeType: "multipart/form-data",
      cache: false,
      contentType: false,
      processData: false,
      dataType: 'json',
      success: function(data) {
        if(data.status === 'OK') {
          $('#license-form').html(data.html);
        }
        $('#flash-js').html(data.flash);
        license.init(license.data.competitionType2ranks, license.data.updateUrl, license.data.emailUrl, license.data.codings, true);
        license.data.isValid = data.isValid;
      },
      error: function(err) {
        console.log('saving failed', e)
      },
      complete: function() {
        license.data.callInProgress = false;
      }
    });
    return false;
  }
};


var initAddRemoveComponent = function(singular, plural, callback) {
  var data = {
    singular: singular,
    plural: plural || singular+'s',
    callback: callback
  };
  var add = function(e) {
    var $el = $( $('#'+data.singular+'-template').html().replace(new RegExp("new_" + data.singular, 'g'), new Date().getTime()) );
    $el.appendTo('#'+data.plural);
    if($el.find('[type="file"]').length > 0) {
      bsCustomInputFile.init();
    }
    if($el.find('.select-picker').length > 0) {
      $el.find('.select-picker').selectpicker();
    }
    if(data.callback) {
      data.callback(e, $el);
    }
  };
  var remove = function(e) {
    e.preventDefault();
    var $wrapperDiv = $(this).closest("."+data.singular+"-wrapper");
    var id = $wrapperDiv.find('[name*="[id]"]').val();
    if(id && id.length > 0) {
      $wrapperDiv.find('[name*="[_destroy]"]').val(1);
      $(this).closest('.'+data.singular).remove();
      $wrapperDiv.hide();
    } else {
      $wrapperDiv.remove(); 
    }
    if(data.callback) {
      data.callback(e);
    }
    return false;
  };

  var removeBtnSelector = ".remove-" + singular + "-btn";
  var addBtnSelector = ".add-" + singular + "-btn";

  $(document).on('click', removeBtnSelector, remove);
  $(document).on('click', addBtnSelector, add);
}

global.tableFilter = {
  data: {},
  init: function(url) {
    tableFilter.data.url = url;
    $(document).on('changed.bs.select', '#filter select', tableFilter.filter);
    $(document).on('click', 'ul.pagination li:not(.disabled)', tableFilter.pageClick);
    $(document).on('click', 'th.table-header', tableFilter.tableHeaderClick);
  },
  tableHeaderClick: function() {
    var $self = $(this);
    var data = $self.data();
    $('#sort_column').val( data.col );
    $('#sort_order').val( data.order );
    tableFilter.filter();
  },
  filter: function() {
    $('.spinner').show();
    var data = $('form#filter').serializeObject();
    data.time = new Date().getTime();
    $.ajax(tableFilter.data.url, {
      data: data,
      success: function(data) {
        if(data.status == 'OK') {
          $('#index-table').html(data.html);
          $('.select-picker').selectpicker();
        } else {
          console.error(data);
        }
      },
      error: function(err) {
          console.error(err);
      },
      complete: function() {
        $('.spinner').hide();
      }
    })
  },
  pageClick: function(e) {
    e.preventDefault();
    $('#page').val($(this).data('page'));
    tableFilter.filter();    
    return false;
  }
}

global.emails = {
  data: { init: true },
  init: function() {
    $(document).on('changed.bs.select', 'select#typeselect', emails.onTypeChanged);
    $('select#typeselect').trigger('changed.bs.select');
  },
  onTypeChanged: function() {
    var val = $(this).val();
    var $fields = $('#fields');
    if(val !== '') {
      var data = $('select#typeselect').find('[value="'+val+'"]').data();
      var $subjectField = $fields.find('[name*="subject"]');
      var $contentField = $fields.find('[name*="content"]');
      var $contentDiv = $fields.find('.contentdiv');
      
      if(!emails.data.init || data.body) {      
        $subjectField.val(data.subject);
        $contentField.val(data.body);
      }

      $subjectField.prop('readonly', !!data.body);
      $contentField.prop('readonly', !!data.body);
      if(!!data.body) {
        // NOT custom email
        $contentField.hide();
        $contentDiv.html(data.body).show();
      } else {
        $contentField.show();
        $contentDiv.hide();
      }
      $fields.show();
    } else {
      $fields.hide();
    }
    emails.data.init = false;
  }
}

global.club = {
  init: function() {
    $(document).on('click', '.add-notification-email', club.addNotificationEmail);
    $(document).on('click', '.remove-notification-email', club.removeNotificationEmail);
  },
  addNotificationEmail: function(e) {
    e.preventDefault();
    var $parent = $(this).parent('[data-templateid]');
    $parent.find('.notification-emails').append( $($parent.data('templateid')).html() );
    return false;
  },
  removeNotificationEmail: function(e) {
    e.preventDefault();
    $(this).closest('.notification-email').remove();
    return false;
  }
}

global.actionModal = {
  data: {},
  init: function(url) {
    actionModal.data.emailUrl = url;
    $(document).on('show.bs.modal', '#actionmodal', actionModal.show);
  },
  show: function(e) {
    var $trigger = $(e.relatedTarget);
    console.log($trigger);
    var $content = $('#actionmodal').find('.modal-content');

    $content.find('.modal-body, .modal-footer').remove();
    $content.append('<div class="modal-body text-center"><div class="spinner-border"></div></div>');

    $.ajax(actionModal.data.emailUrl, {
      method: 'POST',
      data: {
        "email[type]": $trigger.data('emailtype'),
        registration_id: $trigger.data('registrationid'),
        email_action: $trigger.data('action'),
        default: true
      },
      success: function(data) {
        var $content = $('#actionmodal').find('.modal-content');
        $content.find('.modal-body').remove();
        $content.append(data.html);
      },
      error: function(x) {
        console.log("error", x);
      }
    });
  }
}

global.registration = {
  data: {
    callInProgress: false,
    DEFAULT_SAVE_TRESHOLD: 500
  },
  init: function(genderType2ranks, updateUrl, emailUrl, allowedIntComp, allowedIntComp2, allowedHigher, ranksForT, rankT, u14, skipBindings) {
    registration.data.genderType2ranks = genderType2ranks;
    registration.data.updateUrl = updateUrl;
    registration.data.emailUrl = emailUrl;
    registration.data.allowedIntComp = allowedIntComp;
    registration.data.allowedIntComp2 = allowedIntComp2;
    registration.data.allowedHigher = allowedHigher;
    registration.data.rankT = rankT;
    registration.data.ranksForT = ranksForT;
    registration.data.u14 = u14;

    if(skipBindings) {
      selectPicker.init();
    } else {
      $(document).on('changed.bs.select', 'select.competition-type-select', registration.competitionTypeChange);
      $(document).on('changed.bs.select', 'select.gender-select', registration.genderChange);
      initAddRemoveComponent('full_participation', null, registration.flashButton);
      $(document).on('click', '.save-registration-btn', registration.saveRegistration);
      $(document).on('change', '.auto-upload[type="file"]', registration.saveRegistration);
      $(document).on('change', 'select, [type="file"], [type="checkbox"]:not(#showerrors), [type="text"]', registration.flashButton);
      $(document).on('change', '#showerrors', registration.saveRegistration);
      $(document).on('click', '.remove-file', registration.removeFile);
      $(document).on('change', '.has-teams-radio:checked', registration.radioChange);
      $(document).on('changed.bs.select', 'select.ranks-select', registration.ranksChange);
      $(document).on('changed.bs.select', '.intcomp select, .intcomp2 select', registration.intCompChange);
      actionModal.init(registration.data.emailUrl);
    }

    // $('.select-picker').selectpicker();
    // registration.refreshRankOptions();
    registration.closeAlerts();
    bsCustomInputFile.init();
  },
  radioChange: function() {
    var val = $('.has-teams-radio:checked').val();
    var $div = $('.has-teams');
    if(val === 'true') {
      $div.show();
    } else {
      $div.hide();
    }
  },
  removeFile: function(e) {
    e.preventDefault();
    registration.flashButton();
    
    var $self = $(this);
    var $form = $self.closest('form');
    var data = $self.data();
    
    $self.parent().find('.show-file').remove();
    $self.remove();

    $form.append('<input type="hidden" value="true" name="purge['+data.type+']['+data.id+']['+data.field+']" />');
    return false;
  },
  flashButton: function() {
    $('.save-registration-btn:not(.flash)').addClass('flash');
    $('.action-btn').hide();
  },
  closeAlerts() {
    setTimeout(function() {
      $('.alert').closest('.container.alert-minus-margin').fadeOut(400, function() { $(this).remove(); });
    }, 1000);
  },
  intCompChange: function() {
    var $txtField = $(this).closest('.form-row').find('.int-comp-name');
    if($(this).val() === 'true') {
      $txtField.show();
    } else {
      $txtField.hide().find('input').val(null);
    }
  },
  competitionTypeChange: function() {
    var type = $(this).val();
    var $parent = $(this).closest('.full_participation');
    var gender = $parent.find('select.gender-select').selectpicker('val');
    var $ranksSelect = $parent.find('select.ranks-select');
    $ranksSelect.empty().append( registration.buildRankOptions(registration.data.genderType2ranks[gender][type]) );
    $ranksSelect.selectpicker('refresh');

    registration.updateIntCompFields($parent, type, []);
  },
  ranksChange: function(event, clickedIndex, isSelected, previousValue) {
    if(previousValue.length == 0) {
      var current = $(this).val();
      var comp = $('select.competition-type-select').val();
      console.log(comp, registration.data.u14, current[0])
      if(current.length > 0 && ( (comp == registration.data.u14 && current[0] == '1') || ( comp != registration.data.u14 && registration.data.ranksForT.indexOf(current[0]) >= 0) ) ) {
        current.push(registration.data.rankT);
        $(this).selectpicker('val', current);
      }
    }
    var ranks = $(this).val();
    var $parent = $(this).closest('.full_participation');
    var type = registration.getType($parent);
    var gender = $parent.find('select.gender-select').selectpicker('val');

    registration.updateIntCompFields($parent, type, ranks);
    registration.updateHigherLeagueField($parent, gender, ranks);
  },
  updateIntCompFields: function($parent, type, ranks) {
    var lst = [[registration.data.allowedIntComp, '.intcomp'], [registration.data.allowedIntComp2, '.intcomp2']];
    $.each(lst, function(i, data) {
      var selector = data[1];
      var any = false;
      $.each(data[0], function(j, item) {
        any |= item[0] == type && (!item[1] || ranks.indexOf(item[1]) >= 0);
      });
      if(any) {
        $parent.find(selector).show();
      } else {
        $parent.find(selector).hide();
        $parent.find(selector).find('select').selectpicker('val', 'false');
        $parent.find(selector).find('input[type="text"]').val(null);
      }
    });
  },
  updateHigherLeagueField: function($parent, gender, ranks) {
    var selector = '.higher-league';
    var any = false;
    $.each(registration.data.allowedHigher, function(i, item) {
      any |= item[0] == gender && (!item[1] || ranks.indexOf(item[1]) >= 0);
    });
    if(any) {
      $parent.find(selector).show();
    } else {
      $parent.find(selector).hide();
      $parent.find(selector).find('input[type="radio"]').prop('checked', false);
    }
  },
  getType: function($parent) {
    return $parent.find('input.competition-type-select').val() || $parent.find('select.competition-type-select').selectpicker('val');
  },
  genderChange: function() {
    var gender = $(this).val();
    var $parent = $(this).closest('.full_participation');
    var type = registration.getType($parent);
    var $ranksSelect = $parent.find('select.ranks-select');

    $ranksSelect.empty().append( registration.buildRankOptions(registration.data.genderType2ranks[gender][type]) );
    $ranksSelect.selectpicker('refresh');

    registration.updateHigherLeagueField($parent, gender, []);
  },
  buildRankOptions: function(ranks) {
    var html = "";
    $.each(ranks, function(i, rank) {
      html += "<option value='"+rank.value+"'>"+rank.name+"</option>";
    });
    return html;
  },
  refreshRankOptions: function() {
    $('.participation-wrapper').each(function() {
      var $self = $(this);
      var type = registration.getType($parent);
      var $rankSelect = $self.find('select.rank-select');
      var rankVal = $rankSelect.selectpicker('val');
      $rankSelect.empty().append( registration.buildRankOptions(registration.data.genderType2ranks[type]) );
      $rankSelect.selectpicker('val', rankVal);
      $rankSelect.selectpicker('refresh');
    });
  },
  getFormData: function(e) {
    var $form = $('form');
    var data = new FormData($form[0]);
    
    var tmp = {};
    $form.find('[type="checkbox"]').each(function() {
      var $item = $(this);
      if(!tmp[$item.attr('name')]) {
        tmp[$item.attr('name')] = new Array();
      }
      if($item.prop('checked') === true) {
        tmp[$item.attr('name')].push(1);
      } else {
        tmp[$item.attr('name')].push(0);
      }
    });

    for (const [name, arr] of Object.entries(tmp)) {
      data.append(name, arr);
    }
    // // Display the key/value pairs
    // for (var pair of data.entries()) {
    //     console.log(pair[0]+ ', ' + pair[1]); 
    // }
    return data;
  },
  autoSaveRegistration: function(e) {
    if(registration.data.callInProgress) return false;
    $('.submit-registration-btn').remove();
    registration.data.saveTime = new Date().getTime();
    setTimeout(function() {
      var current = new Date().getTime();
      if(registration.data.saveTime <= current - registration.data.DEFAULT_SAVE_TRESHOLD) {
        registration.saveRegistration(e);
      }
    }, registration.data.DEFAULT_SAVE_TRESHOLD);
  },
  saveRegistration: function(e) {
    e.preventDefault();
    if(registration.data.callInProgress) return false;
    registration.data.callInProgress = true;

    $('.noscript').remove();
    $('.save-registration-btn').hide();
    $('.saving-registration-btn').show();

    $.ajax(registration.data.updateUrl, {
      method: 'PUT',
      data: registration.getFormData(),
      mimeType: "multipart/form-data",
      cache: false,
      contentType: false,
      processData: false,
      dataType: 'json',
      success: function(data) {
        if(data.status === 'OK') {
          $('#registration-form').html(data.html);
        }
        $('#flash-js').html(data.flash);
        registration.init(registration.data.genderType2ranks, registration.data.updateUrl, registration.data.emailUrl, registration.data.allowedIntComp, registration.data.allowedIntComp2, registration.data.allowedHigher, registration.data.ranksForT, registration.data.rankT, registration.data.u14, true);
        registration.data.isValid = data.isValid;
      },
      error: function(err) {
        console.log('saving failed', e)
      },
      complete: function() {
        registration.data.callInProgress = false;
      }
    });
    return false;
  }
};

global.emailConfiguration = {
  init: function() {
    initAddRemoveComponent('user_email_configuration');
    $(document).on('change', '.default-select', emailConfiguration.defaultSelect);   
  },
  defaultSelect: function() {
    var $self = $(this);
    var val = $self.selectpicker('val');
    if(val === 'true') {
      $('.default-select').not(this).selectpicker('val', "false");
      $self.selectpicker('val', "true");
    }
  }
}

global.venue = {
  init: function() {
    $('.select-all-btn').click(venue.selectAll);
    $('.clear-all-btn').click(venue.clearAll);
  },
  selectAll: function() {
    $('input[type="checkbox"]').prop('checked', true);
  },
  clearAll: function() {
    $('input[type="checkbox"]').prop('checked', false);
  },
}

global.statistics = {
  init: function() {
    $('.show-stats-btn').click(statistics.showStats);
  },
  showStats: function() {
    $('.spinner').removeClass('hide-important');
    $('table').remove();
    var $form = $('form');
    $.ajax($form.attr('action'), {
      method: 'GET',
      data: $('form').serializeObject(),
      success: function(data) {
        if(data.status == 'OK') {
          $('#stats_table').html(data.html);
        }
      },
      error: function(err) {
        console.error(err);
      },
      complete: function() {
        $('.spinner').addClass('hide-important');
      }
    });
  }
}